// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import './src/jquery';
import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import { Turbo } from "@hotwired/turbo-rails"

// import "./channels"

import 'flatpickr/dist/flatpickr.js'
import GLightbox from 'glightbox'

import 'selectize/dist/js/standalone/selectize.min.js'

import './src/richtext'
import * as FilePond from 'filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from './filepond-plugin-image-validate-size';

import Plyr from 'plyr';
import videojs from 'video.js';
import './controllers';

import 'simplebar';
require("@nathanvda/cocoon");

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import Tribute from "tributejs";
import striptags from 'striptags';
import './src/chart';
import './src/ui';
import './src/timezone';
import './src/search';
import './overview_export';
import './tos_acceptance';

window.cohortMembers = [];
window.bc = new BroadcastChannel('user_channel');
bc.onmessage = function(ev){
  console.log('broadcast ev', ev);
  if(ev.data == 'Logout'){
    window.location = '/';
  }
}

cohortMembers().done(function (data) {
  try {
    window.cohortMembers = JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
})

document.addEventListener("ajax:before", () => {
  $('#loader').removeClass('hidden');
  console.log('ajax start')
})

document.addEventListener("ajax:complete", () => {
  $('#loader').addClass('hidden');
  console.log('COMPLETE')
})

document.addEventListener("turbo:load", function (e) {
  $('.date').flatpickr({
  });
  $('.select-two').selectize();
  $('.select-multiple').selectize({
    maxItems: 2,
    placeholder: 'None'
  });
  $('#curriculum_article_zoom_timestamp').flatpickr({ enableTime: true });

  if (window.articles && $('trix-editor').length > 0) {
    var tribute = new Tribute({
      values: JSON.parse(window.articles),
      //    selectTemplate: function(item) {
      //   return '<span class="fr-deletable fr-tribute">' + item.original.key + '</a></span>';
      // }
    });
    // console.log('window.articles', window.articles, tribute);

    tribute.attach($('trix-editor'));
    var editor = $('trix-editor')[0].editor;
    if (editor != null) {
      editor.composition.delegate.inputController.events.keypress = function (e) {
        // console.log('key keypress', e.keyCode, e.charCode);
      };
      editor.composition.delegate.inputController.events.keydown = function (e) {
        // console.log('key keydown', e.keyCode, e.charCode);
        // tribute.showMenuForCollection();
        // console.log('keys',Object.keys(editor));
      };
    }
  }

  window.selectableMembersTribute = new Tribute({
    values: window.cohortMembers,
    lookup: 'name',
    // column that contains the content to insert by default
    fillAttr: 'name',
    replaceTextSuffix: '',
    selectTemplate: function (item) {
      return "<span contenteditable='false' class='mention' data-id='" + item.original.id + "'>" + item.original.name + "</span>";
    }
  })

  if ($('#post_body_content').length > 0) {
    // console.log('data', data);
    if (!$('#post_body_content').attr('data-tribute')) {
      window.selectableMembersTribute.attach($('#post_body_content'));
    }
  }

  if ($('#comment_body_content').length > 0) {
    if (!$('#post_body_content').attr('data-tribute')) {
      window.selectableMembersTribute.attach($('#comment_body_content'));
    }
  }

  var subcomments_body = document.querySelectorAll(".subcomment-body")
  for (var i = 0; i < subcomments_body.length; i++) {
    if (!subcomments_body[i].getAttribute('data-tribute')) {
      window.selectableMembersTribute.attach(subcomments_body[i]);
    }
  }

  $(document).on('click', '#discount_btn', function (e) {
    e.preventDefault();
    // console.log('discount btn clicked');
    const couponCode = $('#coupon_code').val();
    if (couponCode.length > 0) {
      console.log('validate coupon');
      $('#loader').removeClass('hidden');
      $.post('/signup/coupon/validate', { coupon: couponCode }, function (res) {
        $('#loader').addClass('hidden');
      })
    }
  })

  $(document).on('click', '#payment_discount_btn', function (e) {
    e.preventDefault();
    // console.log('discount btn clicked');
    const couponCode = $('#coupon_code').val();
    if (couponCode.length > 0) {
      console.log('validate coupon');
      $('#loader').removeClass('hidden');
      $.post('/payment/coupon/validate', { coupon: couponCode }, function (res) {
        $('#loader').addClass('hidden');
      })
    }
  })

  const inputElement = document.getElementById('profile_avatar')
  const avatarElem = document.getElementById('user_profile_attributes_avatar')
  FilePond.registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageCrop,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit,
    FilePondPluginFilePoster,
    FilePondPluginFileValidateSize,
    FilePondPluginImageValidateSize
  );
  const filepondOptions = {
    credits: false,
    acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/heic', 'image/heif'],
    labelIdle: `Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
    imagePreviewHeight: 500,
    imageCropAspectRatio: '1:1',
    imageResizeTargetWidth: 500,
    imageResizeTargetHeight: 500,
    stylePanelLayout: 'compact circle',
    styleLoadIndicatorPosition: 'center bottom',
    styleProgressIndicatorPosition: 'center bottom',
    styleButtonRemoveItemPosition: 'center bottom',
    styleButtonProcessItemPosition: 'center bottom',
    allowFileSizeValidation: true,
    maxFileSize: '2MB',
    imageValidateSizeMaxWidth: 2000,
    imageValidateSizeMaxHeight: 2000,
    maxFiles: 1,
    allowMultiple: false,
    allowReplace: true,
    stylePanelAspectRatio: '1:1',
    fileValidateTypeLabelExpectedTypes: `'Expects {allTypes}`,
    allowImageResize: true,
    // onerror:	function(error, file, status){
    //   console.log('error', error, file, status);
    //   alert(error);
    // }
    // storeAsFile: true
    iconRemove: '<i class="fas fa-trash"></i>',
    fileValidateTypeDetectType: (source, type) =>
      new Promise((resolve, reject) => {
          // test if it is heif/heic file
          if (/\.heif$/.test(source.name)) return resolve('image/heif');
          if (/\.heic$/.test(source.name)) return resolve('image/heic');

          // accept detected type
          resolve(type);
      }),
    allowImageValidateSize: true,
    allowImageTransform: true,
    imageTransformOutputMimeType: 'image/jpeg'
  }
  // Create a FilePond instance
  if (inputElement) {
    const pond = FilePond.create(inputElement, filepondOptions);

    const existingAvatar = document.getElementById('existing_avatar')
    const avatarUrl = existingAvatar.dataset.avatarUrl
    const avatarId = existingAvatar.dataset.avatarId

    pond.server = {
      url: '/',
      process: 'upload_avatar',
      revert: null,
      // this is the property you should set in order to render your file using Poster plugin
      load: './avatar/',
      restore: null,
      fetch: null,
      remove: (source, load, error) => {
        // Should somehow send `source` to server so server can remove the file with this source

        // Can call the error method if something is wrong, should exit after
        $.post('/remove_avatar', function () {
          console.log('removed');
        })
        // Should call the load method when done, no parameters required
        load();
      },
    };
    // console.log('avatar url', existingAvatar, avatarUrl);
    if (avatarUrl) {
      pond.files = [
        {
          source: avatarId,
          options: {
            type: 'local',
            metadata: {
              poster: avatarUrl
            }
          }
        }
      ];
    }

    const pondRoot = document.querySelector('.filepond--root');
    if (pondRoot) {
      pondRoot.addEventListener('FilePond:error', e => {
        console.log('Error', e, `${e.detail.error.main}, ${e.detail.error.sub}`);
        alert(`${e.detail.error.main}, ${e.detail.error.sub}`);
        pond.removeFiles();
      });
    }

  }

  if (avatarElem) {
    const pond = FilePond.create(avatarElem, filepondOptions);

    const existingAvatar = document.getElementById('existing_avatar')
    const avatarUrl = existingAvatar.dataset.avatarUrl
    const avatarId = existingAvatar.dataset.avatarId
    const userId = existingAvatar.dataset.userId

    pond.server = {
      url: '/',
      process: `./admin/users/${userId}/upload_avatar`,
      revert: null,
      // this is the property you should set in order to render your file using Poster plugin
      load: `./admin/users/${userId}/avatar/`,
      restore: null,
      fetch: null,
      remove: (source, load, error) => {
        // Should somehow send `source` to server so server can remove the file with this source

        // Can call the error method if something is wrong, should exit after
        $.post(`/admin/users/${userId}/remove_avatar`, function () {
          console.log('removed');
        })
        // Should call the load method when done, no parameters required
        load();
      },
    };
    // console.log('avatar url', existingAvatar, avatarUrl);
    if (avatarUrl) {
      pond.files = [
        {
          source: avatarId,
          options: {
            type: 'local',
            metadata: {
              poster: avatarUrl
            }
          }
        }
      ];
    }
    const pondRoot = document.querySelector('.filepond--root');
    if (pondRoot) {
      pondRoot.addEventListener('FilePond:error', e => {
        console.log('Error', e, `${e.detail.error.main}, ${e.detail.error.sub}`);
        alert(`${e.detail.error.main}, ${e.detail.error.sub}`);
        pond.removeFiles();
      });
    }
  }

  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  function updateZoomComplete(date) {
    fetch("/curriculum/zoom_completed", {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'X-CSRF-Token': csrf
      },
      body: JSON.stringify({
        day: date,
      }),
    })
      .then((result) => {
        return result.json();
      })
      .then((result) => {
        //console.log('result', result);
        if (result.video_completed && result.zoom_completed) {
          $('#video_check i').toggleClass('hidden');
        }
      })

      .catch((error) => {
        console.log('error', error);
      })
  }

  // Like buttons
  // TODO: Might need moving to more appropriate place
  $('.post-footer a.like, .post-footer a.unlike').on('click', function (e) {
    e.preventDefault();
    $(this).parent().find('.like').toggleClass('hidden');
    $(this).parent().find('.unlike').toggleClass('hidden');
    console.log('link', $(this).data());
    fetch($(this).data('link'), {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'X-CSRF-Token': csrf
      }
    })
      .catch((error) => {
        console.log('error', error);
      })
  });

  const audio_players = Plyr.setup('.audio-player');

  const video_elem = document.getElementById('zoom_recorded_video');
  if (video_elem) {
    const video = videojs('zoom_recorded_video');
    var handleTimeUpdate = function () {
      let percentage = video.currentTime() * 100 / video.duration();
      if (percentage > 90) {
        video.off("timeupdate", handleTimeUpdate);
        updateZoomComplete(video.el_.dataset.day);
      }
    }
    if (video) {
      video.on('timeupdate', handleTimeUpdate)
    }
  }

  $(document).on('click', '#zoom_video_join_btn, #zoom_video_join_app_btn', function (e) {
    e.preventDefault();
    e.stopPropagation();
    var day = $(this).data('day');
    if (day && day.length > 0) {
      updateZoomComplete(day);
    }
    window.location = $(this).attr('href');
  })

  function filepondPhotoDiaryOptions(inModal = false) {
    // console.log(inModal);
    return {
      credits: false,
      acceptedFileTypes: ['image/jpeg','image/jpg', 'image/heic', 'image/heif'],
      labelIdle: `<div class="icon"><i class="fad fa-arrow-down-to-square"></i></div>Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`,
      allowImageCrop: false,
      //imageCropAspectRatio: '3:4',
      stylePanelLayout: 'compact integrated',
      styleLoadIndicatorPosition: 'center bottom',
      styleProgressIndicatorPosition: 'center bottom',
      styleButtonRemoveItemPosition: 'center bottom',
      styleButtonProcessItemPosition: 'center bottom',
      allowFileSizeValidation: true,
      maxFileSize: '5MB',
      // allowImageValidateSize: true,
      imageValidateSizeMaxWidth: 5000,
      imageValidateSizeMaxHeight: 5000,
      maxFiles: 1,
      allowMultiple: false,
      allowReplace: true,
      stylePanelAspectRatio: (inModal ? '5:3' : '3:4'),
      fileValidateTypeLabelExpectedTypes: `'Expects {allTypes}`,
      // onerror:	function(error, file, status){
      //   console.log('error', error, file, status);
      //   alert(error);
      // }
      // storeAsFile: true
      iconRemove: '<i class="fas fa-trash"></i>',
      fileValidateTypeDetectType: (source, type) =>
        new Promise((resolve, reject) => {
            // test if it is heif/heic file
            if (/\.heif$/.test(source.name)) return resolve('image/heif');
            if (/\.heic$/.test(source.name)) return resolve('image/heic');

            // accept detected type
            resolve(type);
        }),
      allowImageValidateSize: true,
      imageTransformOutputMimeType: 'image/jpeg'
    }
  }

  window.photo_diary_image = function (prepend_id) {
    if (!prepend_id) {
      prepend_id = '';
    }
    const front_image_id = prepend_id + 'front_image';
    const existing_front_image_id = prepend_id + 'existing_front_image';

    const side_image_id = prepend_id + 'side_image';
    const existing_side_image_id = prepend_id + 'existing_side_image';

    const back_image_id = prepend_id + 'back_image';
    const existing_back_image_id = prepend_id + 'existing_back_image';

    const frontImageElement = document.getElementById(front_image_id);
    const existingFrontImageElement = document.getElementById(existing_front_image_id);

    const sideImageElement = document.getElementById(side_image_id);
    const existingSideImageElement = document.getElementById(existing_side_image_id);

    const backImageElement = document.getElementById(back_image_id);
    const existingBackImageElement = document.getElementById(existing_back_image_id);


    if(frontImageElement){
      const front_photo_pond = FilePond.create(frontImageElement, filepondPhotoDiaryOptions());
      if(front_photo_pond){
        front_photo_pond.server = {
          url: '/',
          process: `./photo/upload/front`,
          revert: null,
          // this is the property you should set in order to render your file using Poster plugin
          load: `./photo/`,
          restore: null,
          fetch: null,
          remove: (source, load, error) => {
            // Should somehow send `source` to server so server can remove the file with this source

            // Can call the error method if something is wrong, should exit after
            $.post(`/photo/remove/front`, function () {
              console.log('removed');
            })
            // Should call the load method when done, no parameters required
            load();
          },
        };
      }

      if (existingFrontImageElement) {
        front_photo_pond.files = [
          {
            source: 'front',
            options: {
              type: 'local',
              metadata: {
                poster: existingFrontImageElement.dataset.image
              }
            }
          }
        ];
      }
      front_photo_pond.on('addfile', (e, file) => {
        if (e) {
          // console.log('Oh no', e, file);
          // alert(`${e.main}, ${e.sub}`);
          // front_photo_pond.removeFiles();
          return;
        }

        // console.log('File added', file);
      });
    }
    if(sideImageElement){
      const side_photo_pond = FilePond.create(sideImageElement, filepondPhotoDiaryOptions());
      if(side_photo_pond){
        side_photo_pond.server = {
          url: '/',
          process: `./photo/upload/side`,
          revert: null,
          // this is the property you should set in order to render your file using Poster plugin
          load: `./photo/`,
          restore: null,
          fetch: null,
          remove: (source, load, error) => {
            // Should somehow send `source` to server so server can remove the file with this source

            // Can call the error method if something is wrong, should exit after
            $.post(`/photo/remove/side`, function () {
              console.log('removed');
            })
            // Should call the load method when done, no parameters required
            load();
          },
        };
      }

      if (existingSideImageElement) {
        side_photo_pond.files = [
          {
            source: 'side',
            options: {
              type: 'local',
              metadata: {
                poster: existingSideImageElement.dataset.image
              }
            }
          }
        ];
      }

      side_photo_pond.on('addfile', (e, file) => {
        if (e) {
          // console.log('Oh no', e);
          // alert(`${e.main}, ${e.sub}`);
          //side_photo_pond.removeFiles();
          return;
        }

        // console.log('File added', file);
      });
    }
    if(backImageElement){
      const back_photo_pond = FilePond.create(backImageElement, filepondPhotoDiaryOptions());
      if(back_photo_pond){
        back_photo_pond.server = {
          url: '/',
          process: `./photo/upload/back`,
          revert: null,
          // this is the property you should set in order to render your file using Poster plugin
          load: `./photo/`,
          restore: null,
          fetch: null,
          remove: (source, load, error) => {
            // Should somehow send `source` to server so server can remove the file with this source

            // Can call the error method if something is wrong, should exit after
            $.post(`/photo/remove/back`, function () {
              console.log('removed');
            })
            // Should call the load method when done, no parameters required
            load();
          },
        };
      }

      if (existingBackImageElement) {
        back_photo_pond.files = [
          {
            source: 'back',
            options: {
              type: 'local',
              metadata: {
                poster: existingBackImageElement.dataset.image
              }
            }
          }
        ];
      }

      back_photo_pond.on('addfile', (e, file) => {
        if (e) {
          // console.log('Oh no', e);
          // alert(`${e.main}, ${e.sub}`);
          //back_photo_pond.removeFiles();
          return;
        }

        // console.log('File added', file);
      });

    }
  }
  photo_diary_image();
  // photo_diary_image('modal_');
  window.photo_diary_image_by_type = function (prepend_id, type) {
    if (!prepend_id) {
      prepend_id = '';
    }
    const image_id = prepend_id + type + '_image';
    const existing_image_id = prepend_id + 'existing_' + type + '_image';
    const ImageElement = document.getElementById(image_id);
    // console.log('Image Element', ImageElement, FilePond.find(ImageElement));
    const existingImageElement = document.getElementById(existing_image_id);
    if(ImageElement && !FilePond.find(ImageElement)){
      const photo_pond = FilePond.create(ImageElement, filepondPhotoDiaryOptions(true));
      if(photo_pond){
        photo_pond.server = {
          url: '/',
          process: {
            url: `./photo/upload/${type}`,
            onload: (response) => {
              $.ajax({
                url: '/current_week_photo',
                type: 'GET',
                dataType: 'script',
                error: function () {
                  console.log('error on fetching photo diary');
                }
              });
            }
          },
          revert: null,
          // this is the property you should set in order to render your file using Poster plugin
          load: `./photo/`,
          restore: null,
          fetch: null,
          remove: (source, load, error) => {
            // Should somehow send `source` to server so server can remove the file with this source

            // Can call the error method if something is wrong, should exit after
            $.post(`/photo/remove/${type}`, function () {
              console.log('removed');
            })
            // Should call the load method when done, no parameters required
            load();
          },
        };
      }

      if (existingImageElement) {
        photo_pond.files = [
          {
            source: `${type}`,
            options: {
              type: 'local',
              metadata: {
                poster: existingImageElement.dataset.image
              }
            }
          }
        ];
      }
    }
  }

  const lightbox = GLightbox({
    'selector': '.lightbox'
  });

  // var ta = autosize($('textarea.autogrow'));

  // ta.addEventListener('autosize:resized', function(){
  //   console.log('textarea height updated');
  // });

  // $('textarea.autogrow').each(function(){

  // }).on('autosize:resized', function(){
  //   console.log('textarea height updated');
  // });

  $('.link-remote').on('ajax:beforeSend', function () {
    $('#loader').removeClass('hidden');
  });
  $(document).on('change', '.plan_day', function () {
    const plan = $(this).val();
    const day = $(this).data('day');
    $('#loader').removeClass('hidden');
    $.ajax({
      url: '/plan_days',
      type: 'POST',
      data: {
        plan: plan,
        day: day
      },
      dataType: 'script',
      error: function () {
        console.log('error setting plan day');
      }
    });
  })

  $(document).on('change', '.admin_plan_day', function () {
    const plan = $(this).val();
    const day = $(this).data('day');
    const user = $(this).data('user');
    $('#loader').removeClass('hidden');
    $.ajax({
      url: `/admin/users/${user}/plan_days`,
      type: 'POST',
      data: {
        plan: plan,
        day: day
      },
      dataType: 'script',
      error: function () {
        console.log('error setting plan day');
      }
    });
  })

  $(document).on('change', '#user_profile_attributes_initial_nutritional_pref, #profile_initial_nutritional_pref', function () {
    const nutritional_pref = $(this).val();
    if (nutritional_pref == 'Other (please specify)') {
      $('#initial_nutritional_pref_exp_cont').removeClass('hidden');
    } else {
      $('#initial_nutritional_pref_exp_cont').addClass('hidden');
      $('#profile_initial_nutritional_pref_exp').val('');
      $('#user_profile_attributes_initial_nutritional_pref_exp').val('');
    }
  })

  $(document).on('change', '#user_profile_attributes_injury_or_activity_restriction, #profile_injury_or_activity_restriction', function () {
    const pref = $(this).val();
    if (pref == 'true') {
      $('#activity_restriction_explanation_cont').removeClass('hidden');
    } else {
      $('#activity_restriction_explanation_cont').addClass('hidden');
      $('#profile_activity_restriction_explanation').val('');
      $('#user_profile_attributes_activity_restriction_explanation').val('');
    }
  })

  $(document).on('change', '#main_goal_form #profile_main_goal', function (e) {
    const form = document.getElementById('main_goal_form');
    Rails.fire(form, 'submit');
  })

  $(document).on('change', '.graph_duration_select', function () {
    let duration = $(this).val();
    let path = $(this).data('path');
    console.log('duration', duration);
    $.ajax({
      url: `${path}/${duration}`,
      type: 'get',
      dataType: 'script'
    });
  })

  $('form').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    // console.log('e, insertedItem, originalEvent', e, insertedItem, originalEvent);
    $(insertedItem).find('.select-two').selectize();
  });

  var hash = window.location.hash;
  if (hash.length > 0) {
    $(hash + '.comment').addClass('highlighted-comment')
  }

  $(document).on('click', '.step_next, .step_prev', function (e) {
    e.preventDefault();
    const target = $(this).data('target');
    $('#weekly_stat_reset').val('0');
    $('.step').addClass('hidden');
    $('#weekly_stat_step').val(target);
    $('#step_' + target).removeClass('hidden');
    $('#step_' + target + ' .question-wrapper').removeClass('answered');
    initializeFilepond(target);
  })

  window.initializeFilepond = function (target) {
    if (target == '7') {
      photo_diary_image_by_type('modal_', 'front');
    } else if (target == '8') {
      photo_diary_image_by_type('modal_', 'side');
    } else if (target == '9') {
      photo_diary_image_by_type('modal_', 'back');
    }
  }

  $(document).on('paste', 'div[contenteditable=true]', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    // console.log('paste', e.target);
    const clipboardData = (e.originalEvent || e).clipboardData || window.clipboardData
    // let pastedData = (e.originalEvent || e).clipboardData.getData('text/plain');
    let pastedData = clipboardData.getData('text/html')
    // console.log('pastedData', pastedData);
    if (pastedData === '') {
      pastedData = clipboardData.getData('text/plain')
    }
    // console.log('pastedData', pastedData);
    // const tagWhiteList = ['h3', 'h2', 'h1', 'p', 'br', 'ul', 'ol', 'li', 'a'];
    const tagWhiteList = [];
    const tagsToBeRemoved = ['head', 'title', 'style', 'script'];
    let wrapper = document.createElement('div')
    wrapper.innerHTML = pastedData
    // console.log('wrapper', wrapper);
    let allChildren = wrapper.getElementsByTagName('*')
    for (let index = 0; index < allChildren.length; index++) {
      const element = allChildren[index];
      // console.log('element', element, element.tagName);
      if (tagsToBeRemoved.includes(element.tagName.toLowerCase())) {
        element.remove();
      } else if (element.tagName.toLowerCase() === 'a') {
        // console.log('anchor', element.textContent, element.getAttribute('href'));
        element.replaceWith(`${element.textContent} ${element.getAttribute('href')}`);
      } else {
        element.removeAttribute('id')
        element.removeAttribute('class')
        element.removeAttribute('style')
      }
      // console.log('element', element);
    }
    // console.log('wrapper after', wrapper);
    pastedData = wrapper.innerHTML || '';
    pastedData = striptags(pastedData, tagWhiteList); // remove all html except the listed tags
    // console.log('pasted data', pastedData);
    window.document.execCommand('insertText', false, pastedData.trim());
  });

  $(document).on('change', '#signup_preferred_unit', function () {
    // console.log('preferred unit', $(this).val());
    const preferred_unit = $(this).val();
    $('.height_stat_container, .weight_unit_label').addClass('hidden');
    $(`#height_stat_${preferred_unit}`).removeClass('hidden');
    $(`#weight_label_${preferred_unit}`).removeClass('hidden');
  })

  $(document).on('change', '#enrollment_course_id', function () {
    const course = $(this).val();
    $.ajax({
      url: `/admin/courses/${course}/cohort_list`,
      type: 'get',
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      success: function (data) {
        var select = $('#enrollment_cohort_id');
        select.html("");
        var optionsHTML = "";
        for (var i = 0; i < data.length; i++) {
          optionsHTML += `<option value=${data[i].id}>${data[i].name}</option>`;
        }
        select.html(optionsHTML);
      }
    });
  })
})


window.checkRequired = function (e, form_id, model, input, error_msg = '') {
  e.preventDefault();
  // console.log('check required');
  var elementId = "#" + model + '_' + input;
  var $element = $("#" + form_id + " " + elementId);
  // console.log('element', $element);
  const value = $element.val();
  if (value == null || (value && value.length === 0)) {
    e.preventDefault;
    error_msg ||= 'Please Enter ' + input
    alert(error_msg);
    return
  }
  var form = document.getElementById(form_id);
  Rails.fire(form, 'submit');
}

window.hideModal = function () {
  var bg_elements = document.getElementsByClassName('modal-bg')
  for (var i = 0; i < bg_elements.length; i++) {
    bg_elements[i].classList.add('hidden');
    bg_elements[i].classList.remove('modal-visible');
  }
  var modal_elements = document.getElementsByClassName('modal')
  for (var i = 0; i < bg_elements.length; i++) {
    modal_elements[i].classList.add('hidden');
    modal_elements[i].classList.remove('modal-visible');
  }
  document.body.classList.remove("overflow-hidden");
}

window.toggleModal = function (modalID) {
  if (document.getElementById(modalID)) {
    document.getElementById(modalID).classList.toggle("hidden");
    document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
    document.getElementById(modalID).classList.toggle("modal-visible");
    document.getElementById(modalID + "-backdrop").classList.toggle("modal-visible");
  }
  // TODO: this doesn't work well when displaying two modals over each other
  document.body.classList.toggle("overflow-hidden");
}

function cohortMembers() {
  return $.ajax({
    url: '/cohort/members',
    type: 'GET',
    async: false,
    dataType: 'script',
  });
}

window.copy_body_content = function () {
  let content = document.getElementById("post_body_content").innerHTML;
  content = content.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  // console.log('content', content);
  content = striptags(content, ['span'], '\n');
  // console.log('content', content);
  document.getElementById("post_body").value = content;
  // console.log('document.getElementById("post_body_content").innerHTML', document.getElementById("post_body_content").innerHTML);
  // console.log('document.getElementById("post_body").value', document.getElementById("post_body").value);
  return true;
}

window.copy_comment_body_content = function (event) {
  if (document.getElementById("comment_body_content").innerText.trim().length == 0) {
    event.preventDefault();
    // event.stopPropagation();
    event.stopImmediatePropagation();
    $('#loader').addClass('hidden');
    // console.log('loader hidden');
    return false;
  }
  let content = document.getElementById("comment_body_content").innerHTML;
  // console.log('comment before', content);
  content = content.replace(/&amp;lt;/g, '<').replace(/&amp;gt;/g, '>').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  content = striptags(content, ['span'], '\n');
  // console.log('comment after', content);
  document.getElementById("comment_body").value = content;

  return true;
}

function resetSelectized(select) {
  // console.log('select', select, select.selectize);
  if (!select.selectize) {
    return;
  }
  const selectedValue = select.selectize.getValue()
  select.selectize.destroy()
  if (selectedValue) {
    const $select = $(select)
    $select.find('option').attr('selected', null)
    $select.find(`option[value=${selectedValue}]`).attr('selected', true)
  }
}

function resetButtonSelect(elem) {
  const $elem = $(elem);
  $elem.find('.selector').remove();
}

function isHeic(fileType) { // check file for heic
  console.log('isHeic', fileType == 'image/heic' || fileType == 'image/heif')
  return fileType == 'image/heic' || fileType == 'image/heif';
}

document.addEventListener('turbo:before-cache', function (e) {
  $('.select-two').each(function (index) {
    resetSelectized(this);
  })
  $('.button-select, .button-select-group').each(function () {
    resetButtonSelect(this);
  })
})

window.contactCoach = function(e) {
  e.preventDefault();
  alert('If you wish to leave this group, please contact your Coach.')
}